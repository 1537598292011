@use "scss/theme";

.app {
  background-color: theme.$surfaceColor;
  width: 100%;
  height: 100%;
  position: fixed;
}

.hiddenScroll {
  background-color: transparent;
  width: 100%;
  height: 1000%;
  //background-image: linear-gradient(45deg, blue, red);
  //visibility: hidden;
}
