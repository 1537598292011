

.top-bar {

  position: absolute;
  right: 0;
  height: 10vh;
  width: 100%;
  user-select: none;
  //background-color: red;
  pointer-events: all;

  justify-content: right;
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: 1vmax;
    margin-top: 10px;
  }


  .social {
    height: 80%;
    aspect-ratio: auto;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;


    &:hover {
      cursor: pointer;
      scale: 105%;
    }
  }
}