
$surfaceColor: #121212;
$color1: #395B64;
$color2: #A5C9CA;
$color3: #E7F6F2;
$textColor: #FFFFFF;
$main-animation-length: 0.75s;

$stepsPerHeight: 1.1;

@function getBgColor ($height) {
  @return lighten($surfaceColor, $height * $stepsPerHeight);
}



:export {
  color1: $color1;
  color2: $color2;
  color3: $color3;
  surfaceColor: $surfaceColor;
  main-animation-length: $main-animation-length;
}
