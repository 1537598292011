@use "theme";

* {
  padding: 0;
  margin: 0;
  color: theme.$textColor;
  font-family: "Century Gothic", sans-serif;
  //font-weight: bold;
  text-transform: lowercase;
}

html, #root, body {
  width: 100%;
  height: 100%;
}

