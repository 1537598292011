@use "scss/theme";


.name-section {
  offset: 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: transparent;
  transition: 2s ease-out;


  .title-box {
    position: absolute;
    width: 80%;
    z-index: 1;
    pointer-events: none;
    transition: all theme.$main-animation-length ease-out;
    transform: translateY(0);

    @media (max-width: 800px) {
      transform: translateY(10vh);
    }
    top: 0;
  }

  .title-box-unclicked {
    top: 50%;
    transform: translateY(-50%);

  }

  .backgroundCanvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .name {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 8vmin;
    transition: all theme.$main-animation-length ease-in-out;
    //white-space: nowrap;
  }

  .name-unclicked {
    font-size: 10vmax;

    @media (max-width: 800px) {
      font-size: 15vw;
    }
  }

  .sections-box {
    font-size: 4vmin;
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    margin: 0 auto;

    .section-select-button {
      //margin: 0;
      margin: 10px auto 10px auto;
      padding: 5px 10px 10px;
      border-radius: 2vmin;

      pointer-events: all;
      cursor: pointer;
      transition: ease-out 0.25s;
      user-select: none;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(10px);

      &:hover {
        transform: scale(1.15);
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);

      }
    }

    .section-select-button-selected {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);

      transform: scale(1.1);

    }

  }
}


.triangle {
  position: fixed;
  width: 5%;
  left: 50%;
  margin-left: -2.5%;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 10%;
  }

  transition: all 1s ease-in-out;
  transition-duration: 0.2s;


  animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;

  animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;

  animation-duration: theme.$main-animation-length;
  -moz-animation-duration: theme.$main-animation-length;
  -webkit-animation-duration: theme.$main-animation-length;

  animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  animation-name: fade-in;
  -moz-animation-name: fade-in;
  -webkit-animation-name: fade-in;



  &:hover {
    transform: scale(1.1);
  }
}

.upTriangle {

  top: 20%;

  @media (max-width: 800px) {
    top: 20%
  }
}

.downTriangle {
  bottom: 5%;
  rotate: z 180deg;
}


