@use "scss/theme";



@keyframes fade-in {
  from {
   opacity: 0;
    transform: translateY(20%);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.main-section {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  position: absolute;
  width: 38%;
  height: 55%;
  left: 10%;
  top: 100% - 55% - 15%;
  box-sizing: border-box;
  pointer-events: none;

  border-radius: 50px;
  padding: 30px;
  font-size: 3vmin;

  overflow: hidden;
  text-transform: none;


  transition: ease-in;
  transition-delay: 0s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  animation-duration: theme.$main-animation-length;
  animation-timing-function: ease-in-out;
  animation-name: fade-in;

  &>* {
    text-transform: none;
  }
  
  @media (max-width: 800px) {
    width: 80%;
    height: 30%;
    left: 10%;
    top: 25%
  }

}


